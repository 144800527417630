<template>
  <v-container>
    <v-row>
      <v-col v-for="(item, i) in itemsContratoCard" :key="i" cols="3">
        <v-card outlined>
          <div class="text-center">
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <p class="text--primary font-weight-regular text-h6">
              Contrato - parte {{ item.parte }}
            </p>

            <v-row justify="center">
              <v-col cols="12" md="10" xl="7">
                <v-select
                  v-model="item.estado"
                  :items="itemsEstado"
                  label="Estado"
                  rounded
                  single-line
                  dense
                  append-icon="mdi-chevron-down"
                  class="px-1"
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
          <v-card-actions class="justify-space-around pb-6 mt-n2">
            <v-btn
              x-small
              color="grey"
              outlined
              class="px-4 mr-n2"
              elevation="1"
              @click="fnContratoFisico(item)"
            >
              Físico
            </v-btn>

            <v-btn
              x-small
              color="grey"
              outlined
              class="px-4 ml-n2"
              elevation="1"
              @click="fnContratoDigital(item)"
            >
              Digital
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <p class="text-h6 font-weight-regular mt-2">Revisión contrato cargado</p>

    <!-- ventana de dialogo para mostrar la fotografía del contrato físico -->
    <v-dialog v-model="dialogFisico" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1"
          >Fotografía contrato parte {{ infoContrato.parte }}</v-card-title
        >
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img
            alt="gotografía contrato"
            class="shrink"
            contain
            :src="computedContratoImagen(infoContrato.parte)"
            width="300"
          />
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn
            color="#031D6A"
            class="px-5 white--text mr-4"
            small
            @click="fnDescargarContratoFotografia(infoContrato)"
          >
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogFisico = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ventana de dialogo para mostrar el contrato digital -->
    <v-dialog v-model="dialogDigital" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1"
          >Contrato digital parte {{ infoContrato.parte }}</v-card-title
        >
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img
            alt="contrato digital"
            class="shrink"
            contain
            :src="computedContratoImagen(infoContrato.parte)"
            width="300"
          />
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn
            color="#031D6A"
            class="px-5 white--text mr-4"
            small
            @click="fnDescargarContratoDigital(infoContrato)"
          >
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogDigital = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'vistaContrato',
  data() {
    return {
      //variables para las ventánas de dialogo que muestran las ventanas de los contratos
      dialogFisico: false,
      dialogDigital: false,

      //items temporales para las cards de el apartado de CONTRATO
      itemsContratoCard: [
        {
          parte: 3,
          fisicoLink: 'fisico3',
          digitalLink: 'digital3',
          estado: null,
        },
      ],

      infoContrato: {}, //variable para mostrar la parte del contrato en la ventana de diálogo cuando se da clic en los respectivos botones

      estado: null, //para almacenar el estado que tiene
      //items para mostrar cuando se da clic en el menú estado
      itemsEstado: ['Aprobado', 'Rechazado', 'Pendiente'],
    };
  },

  methods: {
    computedContratoImagen(contratoParte){
      if (this.detalleEvaluacion.tipo_de_persona_nacionalidad == 'extranjero') {
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVExtranjera1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVExtranjera2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVExtranjera3.jpg');
          default:
          return require('@/assets/images/contrato.jpg');
        }
      }else{
        switch (contratoParte) {
          case 1:
            return require('@/assets/images/ContratoSVNacional1.jpg');
          case 2:
            return require('@/assets/images/ContratoSVNacional2.jpg');
          case 3:
            return require('@/assets/images/ContratoSVNacional3.jpg');
          default:
          return require('@/assets/images/contrato.jpg');
        }

      }
    },
    //-----funciones cuando se da clic en los botones de FÍSICO - DIGITAL en las cards del apartado de CONTRATO

    //clic en botón FÍSICO
    fnContratoFisico(item) {
      this.infoContrato = Object.assign(item);
      console.log('se dio clic en el botón', this.infoContrato); // comprobando que si se da clic sobre items diferentes dependiendo de en que card esté el botón
      this.dialogFisico = true;
    },

    //clic en botón DIGITAL
    fnContratoDigital(item) {
      this.infoContrato = Object.assign(item);
      console.log('se dio clic en el botón', this.infoContrato);
      this.dialogDigital = true;
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en las respectivas ventanas de dialogo, fotografía de contrato y contrato digital
    fnDescargarContratoFotografia(data) {
      //se vuelve a recibir como parámetro this.infoContrato, para verificar que si se está dentro de la ventana deseada y se está descarnango la info correcta, pero no debería ser necesario ya que la info estaría guardada temporalmente en this.infoContrato
      console.log('descargando fotografía...', data.fisicoLink);
      console.log(this.infoContrato.fisicoLink); //comprobamos si muestran la misma información (así debería de ser)
    },
    fnDescargarContratoDigital(data) {
      console.log('descargando digital...', data.digitalLink);
      console.log(this.infoContrato.digitalLink);
    },

    //funcion de ****prueba unicamente para comprobar si se tiene algun pendiente y mostrar las palabras Pendiente o Aprobado
    //   fnRevisarPendientes() {
    //     const pendiente = localStorage.getItem('pendientes');
    //     if (pendiente) {
    //       console.log('se tiene pendientes');
    //       //cambiamos los estados de la card (simulando como que se trajera la info de la base de datos) para mostrar que tienen pendientes
    //       this.itemsContratoCard = [
    //         {
    //           parte: 1,
    //           fisicoLink: 'fisico1',
    //           digitalLink: 'digital1',
    //           estado: 'Aprobado',
    //         },
    //         {
    //           parte: 2,
    //           fisicoLink: 'fisico2',
    //           digitalLink: 'digital2',
    //           estado: 'Pendiente',
    //         },
    //         {
    //           parte: 3,
    //           fisicoLink: 'fisico3',
    //           digitalLink: 'digital3',
    //           estado: 'Pendiente',
    //         },
    //       ];
    //     } else {
    //       console.log('no tiene pendientes');
    //     }
    //     localStorage.removeItem('pendientes'); //eliminamos la info almacenada de revisión
    //   },
  },

  //para verificar si se tiene algun pendiente (únicamente para pruebas)
  // created() {
  //   this.fnRevisarPendientes();
  // },
};
</script>

<style scoped>
.v-select .v-input--dense .v-select__selection--comma {
  margin: 0px 0px 0px 20px;
}
</style>
